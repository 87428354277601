import type {
  AboveHeaderBlock,
  AdminPanelResponse,
  ContactsBlock,
  DesktopMenuItem,
  FooterMenuItem,
  MobileMenuItem,
  SettingsItem,
  SocialNetworksLinks,
} from '~/types/SiteSettings';
import type { Sitemap } from '~/types/Sitemap';

export const useSiteStore = defineStore('site', {
  state: () => ({
    contacts: {
      phone: '' as string,
      tgLink: '' as string,
      copyrightText: '' as string,
    },
    socialNetworkLinks: {
      tiktokUrl: '' as string | null,
      vkUrl: '' as string | null,
      youtubeUrl: '' as string | null,
      instagramUrl: '' as string | null,
    },
    aboveHeaderBlock: {
      text: '' as string,
      url: '' as string,
      isActive: false as boolean,
    },
    isShowAboveHeaderBlock: false,
    currentPageBreadcrumb: '',
    isMobileMenuOpen: false,
  }),

  actions: {
    async getDesktopMenu() {
      try {
        const { data } = await useCustomFetch<DesktopMenuItem[]>('/layout/desktop-menu');

        if (data.value) {
          return data.value;
        } else {
          return [];
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return [];
      }
    },

    async getMobileMenu() {
      try {
        const { data } = await useCustomFetch<MobileMenuItem[]>('/layout/mobile-menu');

        if (data.value) {
          return data.value;
        } else {
          return [];
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return [];
      }
    },

    async getFooterMenu() {
      try {
        const { data } = await useCustomFetch<FooterMenuItem[]>('/layout/footer-menu');

        if (data.value) {
          return data.value;
        }
        return [];
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return [];
      }
    },

    async getSiteSettings() {
      try {
        const { data } = await useCustomFetch<SettingsItem[]>('/layout/settings');

        if (data.value) {
          const siteSettings = data.value;
          const mappedSettings: { [key: string]: SettingsItem } = {};

          for (const i in siteSettings) {
            if (siteSettings[i]) mappedSettings[siteSettings[i].slug] = siteSettings[i];
          }

          this.setContactsBlockData(mappedSettings.footer_menu_information.fields);
          this.setSocialNetworksLinks(mappedSettings.footer_menu_social_networks.fields);
          this.setAboveHeaderBlockData(mappedSettings.header_old_site.fields);
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
      }
    },

    async getAdminPanelData() {
      try {
        const { data } = await useCustomFetch<{ data: AdminPanelResponse }>('/adminbar');

        if (data.value) {
          return data.value.data;
        } else {
          return null;
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
        return null;
      }
    },

    async getSitemap() {
      try {
        const { data } = await useCustomFetch<Sitemap>('/sitemap');

        if (data.value) {
          return data.value;
        }
      } catch (e) {
        if (e && process.env.NODE_ENV !== 'production') {
          console.error(e);
        }
      }
    },

    setContactsBlockData(fields: ContactsBlock) {
      this.contacts = {
        phone: fields.phone ?? '',
        tgLink: fields.question_url ?? '',
        copyrightText: fields.copyright_text ?? '',
      };
    },

    setSocialNetworksLinks(fields: SocialNetworksLinks) {
      this.socialNetworkLinks = {
        tiktokUrl: fields.tt_url ?? null,
        vkUrl: fields.vk_url ?? null,
        youtubeUrl: fields.youtube_url ?? null,
        instagramUrl: fields.instagram_url ?? null,
      };
    },

    setAboveHeaderBlockData(fields: AboveHeaderBlock) {
      this.aboveHeaderBlock = {
        text: fields.text ?? '',
        url: fields.url ?? '',
        isActive: fields.is_active ?? false,
      };
    },

    hideAboveHeaderBlock() {
      this.isShowAboveHeaderBlock = false;
      sessionStorage.setItem('oldSiteLinkShow', 'false');
    },

    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      this.toggleBodyScrollLock(!!this.isMobileMenuOpen);
    },

    toggleBodyScrollLock(lockState = false) {
      const body = document.querySelector('body');

      if (lockState) {
        if (body) {
          body.style.overflow = 'hidden';
          body.style.paddingRight = 'var(--scrollbar-width)';
        }
      } else {
        body?.removeAttribute('style');
      }
    },
  },
});
